<template>
  <g>
    <path
      d="M78 15.7558V25.9358H72.636V17.1883C72.6524 14.7806 70.803 12.7839 68.4335 12.6514H68.2101C67.0267 12.6499 65.8773 13.0518 64.946 13.7927V25.95H59.5347V7.8094H64.9396V8.59671C67.4049 7.64205 70.1146 7.55292 72.636 8.34354L72.6976 8.36427C75.8656 9.39801 78.0101 12.3931 78 15.7697"
      fill="white"
    />
    <path
      d="M8.93865 7.90533C4.00161 7.90533 0 11.9663 0 16.9744C0 21.9842 4.00161 26.0437 8.93865 26.0437C13.8749 26.0437 17.8769 21.9842 17.8769 16.9744C17.8769 11.9663 13.8749 7.90533 8.93865 7.90533ZM8.93865 21.2854C6.59573 21.2854 4.69025 19.3517 4.69025 16.9744C4.69025 14.598 6.59573 12.6642 8.93865 12.6642C11.2811 12.6642 13.1863 14.598 13.1863 16.9744C13.1863 19.3517 11.2811 21.2854 8.93865 21.2854Z"
      fill="white"
    />
    <path
      d="M26.6106 7.84747H31.7534V12.591H26.6106V19.1245C26.6106 19.5088 26.6327 19.8473 26.6776 20.1407C26.7218 20.4352 26.8449 20.6831 27.0448 20.8859C27.2448 21.0902 27.5564 21.243 27.9796 21.3447C28.4025 21.4458 28.9926 21.4969 29.7501 21.4969C30.2619 21.4969 30.796 21.4684 31.3525 21.4118C31.9092 21.3552 32.4096 21.259 32.855 21.1237V26.0705C32.4096 26.1829 31.8977 26.2686 31.319 26.3249C30.7404 26.3806 30.1388 26.4094 29.516 26.4094C28.0014 26.4094 26.7218 26.2623 25.6758 25.9693C24.6293 25.6758 23.7828 25.2518 23.1371 24.6982C22.4917 24.1453 21.2678 23.0553 21.2678 19.8023V2.94175H26.6106V7.84747Z"
      fill="white"
    />
    <g opacity="0.699997">
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M37.0922 28.3502C36.6678 29.0927 35.7304 29.3456 34.9986 28.9151C34.2667 28.4845 34.0174 27.5334 34.4418 26.7907C34.8662 26.0481 35.8037 25.7952 36.5354 26.2258C36.8864 26.4324 37.1422 26.7715 37.247 27.169C37.3518 27.5679 37.2962 27.9929 37.0922 28.3502Z"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M57.9818 7.20945C57.5557 7.95101 56.6178 8.20184 55.8869 7.76955C55.156 7.33726 54.9088 6.3857 55.335 5.64392C55.7608 4.90225 56.6988 4.65154 57.4297 5.08382C57.7795 5.29072 58.0343 5.6296 58.1386 6.02651C58.2433 6.42631 58.1869 6.85187 57.9818 7.20945Z"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M35.0043 7.78624C34.2701 7.35577 34.0188 6.40271 34.4432 5.65773C34.8676 4.91264 35.8068 4.65775 36.5411 5.08833C37.2753 5.5188 37.5266 6.47186 37.1022 7.21684C36.8986 7.57421 36.5637 7.83508 36.1707 7.94206C35.7769 8.04925 35.3574 7.99325 35.0043 7.78624Z"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M55.8824 28.9225C55.1476 28.4922 54.8958 27.5392 55.3199 26.7935C55.7438 26.0481 56.6832 25.7925 57.418 26.2227C58.1522 26.6527 58.4044 27.6051 57.9813 28.3504C57.5573 29.0965 56.6177 29.3526 55.8825 28.9227C55.8824 28.9227 55.8824 28.9225 55.8823 28.9225"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M41.0643 3.3458C40.6403 2.6006 40.8917 1.64765 41.6261 1.21739C42.3607 0.787139 43.2998 1.04234 43.724 1.78754C44.148 2.53274 43.8964 3.48559 43.162 3.91584C43.1611 3.91637 43.1601 3.91702 43.1595 3.91755C42.4251 4.34567 41.4879 4.08993 41.0643 3.3458Z"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M48.7004 32.2187C48.2797 31.4742 48.5335 30.5242 49.2675 30.0972C50.0015 29.6702 50.9376 29.9278 51.3584 30.6726C51.7791 31.4173 51.5253 32.3672 50.7916 32.7941C50.4404 32.9984 50.0236 33.0534 49.6328 32.9468C49.239 32.8394 48.9036 32.5774 48.7004 32.2187Z"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M48.5251 2.5613C48.5228 1.70057 49.2087 1.00111 50.0569 0.998869C50.9053 0.996625 51.5946 1.69256 51.5968 2.55317C51.5991 3.4139 50.9132 4.11336 50.065 4.1156H50.0608C49.2142 4.11549 48.5273 3.42031 48.5251 2.5613Z"
          fill="white"
        />
      </g>
      <g opacity="0.699997">
        <path
          opacity="0.699997"
          d="M40.8785 31.4396C40.8763 30.579 41.5622 29.8793 42.4105 29.8771C43.2587 29.8748 43.9482 30.5708 43.9503 31.4316C43.9527 32.2923 43.2667 32.9915 42.4184 32.9938H42.4143C41.5677 32.9938 40.8807 32.2985 40.8785 31.4396Z"
          fill="white"
        />
      </g>
    </g>
    <path
      d="M46.2124 5.72617C47.4343 5.72617 48.4248 6.73127 48.4248 7.97096C48.4248 9.21075 47.4343 10.2156 46.2124 10.2156C44.9904 10.2156 44 9.21075 44 7.97096C44 6.73127 44.9904 5.72617 46.2124 5.72617Z"
      fill="white"
    />
    <path
      d="M55.0367 14.6558C56.2588 14.6558 57.2491 15.6609 57.2491 16.9005C57.2491 18.1403 56.2588 19.1453 55.0367 19.1453C53.8148 19.1453 52.8244 18.1403 52.8244 16.9005C52.8244 15.6609 53.8148 14.6558 55.0367 14.6558Z"
      fill="white"
    />
    <path
      d="M46.2124 23.5854C47.4343 23.5854 48.4248 24.5904 48.4248 25.8303C48.4248 27.07 47.4343 28.075 46.2124 28.075C44.9904 28.075 44 27.07 44 25.8303C44 24.5904 44.9904 23.5854 46.2124 23.5854Z"
      fill="white"
    />
    <path
      d="M37.3879 14.6558C38.61 14.6558 39.6005 15.6609 39.6005 16.9005C39.6005 18.1403 38.61 19.1453 37.3879 19.1453C36.166 19.1453 35.1755 18.1403 35.1755 16.9005C35.1755 15.6609 36.166 14.6558 37.3879 14.6558Z"
      fill="white"
    />
    <path
      d="M52.4521 8.34177C53.6741 8.34177 54.6645 9.34677 54.6645 10.5866C54.6645 11.8263 53.6741 12.8312 52.4521 12.8312C51.2302 12.8312 50.2397 11.8263 50.2397 10.5866C50.2397 9.34677 51.2302 8.34177 52.4521 8.34177Z"
      fill="white"
    />
    <path
      d="M52.4521 20.9698C53.6741 20.9698 54.6645 21.9749 54.6645 23.2146C54.6645 24.4542 53.6741 25.4594 52.4521 25.4594C51.2302 25.4594 50.2397 24.4542 50.2397 23.2146C50.2397 21.9749 51.2302 20.9698 52.4521 20.9698Z"
      fill="white"
    />
    <path
      d="M39.9726 20.9698C41.1945 20.9698 42.185 21.9749 42.185 23.2146C42.185 24.4542 41.1945 25.4594 39.9726 25.4594C38.7507 25.4594 37.7602 24.4542 37.7602 23.2146C37.7602 21.9749 38.7507 20.9698 39.9726 20.9698Z"
      fill="white"
    />
    <path
      d="M39.9726 8.34177C41.1945 8.34177 42.185 9.34677 42.185 10.5866C42.185 11.8263 41.1945 12.8312 39.9726 12.8312C38.7507 12.8312 37.7602 11.8263 37.7602 10.5866C37.7602 9.34677 38.7507 8.34177 39.9726 8.34177Z"
      fill="white"
    />
    <path
      d="M46.2226 12.9363C48.3884 12.9363 50.1444 14.7132 50.1444 16.9048C50.1444 19.0966 48.3884 20.8734 46.2226 20.8734C44.0568 20.8734 42.3008 19.0966 42.3008 16.9048C42.3008 14.7132 44.0568 12.9363 46.2226 12.9363Z"
      fill="white"
    />
  </g>
</template>
