<template>
  <router-view />
</template>

<script>

export default {
  name: 'LayoutBase',
  components: {

  },
};
</script>

<style lang="stylus"></style>
